import React, { useEffect } from 'react';

const SharedSesionAuthHOC = Component =>
  function Comp(props) {
    useEffect(() => {
      window.addEventListener('storage', event => {
        const credentials =
          window.sessionStorage == null ? true : window.sessionStorage.getItem('token');
        if (window.sessionStorage !== null) {
          if (event.key === 'REQUESTING_SHARED_TOKEN' && credentials) {
            window.localStorage.setItem('token', credentials);
            window.localStorage.removeItem('token');
          }
          if (event.key === 'token' && !credentials) {
            window.sessionStorage.setItem('token', event.newValue);
          }
          if (event.key === 'REMOVE_TOKEN' && credentials) {
            window.sessionStorage.removeItem('token');
            window.location.reload(true);
          }
        }
      });
    }, []);
    useEffect(() => {
      if (window.sessionStorage !== null) {
        window.localStorage.setItem('REQUESTING_SHARED_TOKEN', Date.now().toString());
        window.localStorage.removeItem('REQUESTING_SHARED_TOKEN');
      }
    }, []);

    return <Component {...props} />;
  };
export default SharedSesionAuthHOC;
