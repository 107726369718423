import React, { Suspense, useEffect, useState } from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import Loader from 'components/Loader/Loader';
import { toast } from 'react-toastify';
import GlobalStyle from 'theme/GlobalStyle';
import 'react-toastify/dist/ReactToastify.css';
// import UserPanel from 'views/UserPanel';
import Notification from 'components/Notification/Notification';
import { ReactComponent as InfoIcon } from 'assets/Notifications/info.svg';
import { ReactComponent as ErrorIcon } from 'assets/Notifications/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/Notifications/success.svg';
import { ReactComponent as WarningIcon } from 'assets/Notifications/warning.svg';
import SharedSesionAuthHOC from 'helpers/SharedSesionAuthHOC';
import jwt from 'jsonwebtoken';
import * as Sentry from '@sentry/browser';
import { componentLoader } from 'helpers/componentLoader';
import Service from 'components/Service/Service';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/MainTheme';
// Sentry.init({ dsn: "https://55a7224b6d734aa9a7bb228653e419a4@o413771.ingest.sentry.io/5301789" });
import { getBuildDate } from 'helpers/utils';

toast.configure();
const queryConfig = {
  suspense: true,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchAllOnWindowFocus: false,
  refetchInterval: false
  // position: toast.POSITION.BOTTOM_RIGHT,
  // manual: true,
  // retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
  // staleTime: 0,
  // cacheTime: 5 * 60 * 1000,
  // refetchInterval: false,
  // refetchOnMount: true,
  // isDataEqual: (previous, next) => true,
};

function App() {
  const [currentUser, setCurrentUser] = useState({
    isLoggedIn: false,
    userEmail: '',
    userId: '',
    tokenExpires: '',
    tokenCreated: ''
  });
  const [awaitForLogin, setAwaitForLogin] = useState(false);

  const UserPanel = React.lazy(() => componentLoader(() => import('views/UserPanelQr')), 5);

  const notifications = (status, msgText) => {
    if (status !== null) {
      switch (status) {
        case true:
          toast.success(<Notification msgText={msgText} icon={<SuccessIcon />} />);
          break;
        case 'info':
          toast.info(<Notification msgText={msgText} icon={<InfoIcon />} />, { autoClose: 10000 });
          break;
        case 'warning':
          toast.warn(<Notification msgText={msgText} icon={<WarningIcon />} />);
          break;
        default:
          toast.error(<Notification msgText={msgText} icon={<ErrorIcon />} />);
          break;
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('t') !== null) {
      window.sessionStorage.setItem('token', urlParams.get('t'));
    }
  }, []);

  useEffect(() => {
    const token = window.sessionStorage == null ? true : window.sessionStorage.getItem('token');
    if (token) {
      const decodedUserInfo = {
        email: jwt.decode(token)?.email,
        id: jwt.decode(token)?.id,
        tokenCreated: Date.now(),
        tokenExpires: jwt.decode(token)?.exp
      };
      setCurrentUser({
        isLoggedIn: true,
        userEmail: decodedUserInfo.email,
        userId: decodedUserInfo.id,
        tokenCreated: Date.now(),
        tokenExpires: decodedUserInfo.tokenExpires
      });
      setAwaitForLogin(true);
    }
  }, []);

  return (
    <ReactQueryConfigProvider config={queryConfig}>
      {console.log('build date: ', getBuildDate())}
      <GlobalStyle />
      <ThemeProvider theme={theme} >
        <Suspense fallback={<Loader />}>
          <UserPanel
            notifications={notifications}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            awaitForLogin={awaitForLogin}
            setAwaitForLogin={setAwaitForLogin}
          />
          {/* <Service /> */}
        </Suspense>
      </ThemeProvider>
    </ReactQueryConfigProvider>
  );
}

export default SharedSesionAuthHOC(App);
// export default App;
